<template>
  <div>
    <van-nav-bar @click-left="back" @click-right="home" :border="false">
      <template #title>
        <span style="color: '#666'"> 工单详情 </span>
      </template>
      <template #left>
        <van-icon name="arrow-left" size="25px" />
      </template>
      <template #right>
        <van-icon name="wap-home-o" size="25px" />
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-cell title="工单名称：" :value="detail.orderName" center />
      <van-cell title="工单等级：" :value="detail.orderLevel" center />
      <van-cell title="工单类型：" :value="detail.orderType" center />
      <van-cell title="站点名称：" :value="detail.siteName" center />
      <van-cell
        title="创建时间："
        :value="parseTime(detail.createTime)"
        center
      />
      <van-cell title="截止时间：" :value="parseTime(detail.endTime)" center />
      <van-cell title="创建人员：" :value="detail.createName" center />
      <!-- <van-cell title="备注：" :label="detail.note" /> -->
      <template v-if="detail.assignName">
        <van-cell title="指派人员：" :value="detail.assignName" center />
        <van-cell
          title="指派时间："
          :value="parseTime(detail.assignTime)"
          center
        />
      </template>
      <template v-if="detail.disposeName">
        <van-cell title="完成人：" :value="detail.disposeName" center />
        <!-- <van-cell
          title="完成时间："
          :value="parseTime(detail.disposeTime)"
          center
        /> -->
      </template>
    </van-cell-group>

    <van-cell-group class="mgt10" v-if="mapImg">
      <van-cell title="位置：" title-class="title">
        <template #label>
          <img @click="jumpXcx" class="map" :src="mapImg" />
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="mgt10 pdb50">
      <van-cell title="工单描述：" :label="detail.orderContent" />
      <FieldLabel name="工单记录" />
      <van-steps
        direction="vertical"
        center
        active-color="#1989fa"
        inactive-color="#999"
        :active="recordList.length - 1"
      >
        <van-step v-for="(item, index) in recordList" :key="index">
          <div class="flex-col">
            <div class="flex-grow-0">
              {{ item.disposeResult }}
            </div>
            <div
              class="flex-grow-0 mgt5"
              v-if="index == recordList.length - 1 || index === 0"
            >
              {{ item.address }}
            </div>
            <div class="flex-grow-0 mgt5" v-if="item.imglist">
              <span>处理前</span>
              <van-image
                v-for="(img, idx) in item.imglist"
                :key="idx"
                width="1.3333rem"
                height="1.3333rem"
                :src="img.src"
                @click="showImagePreview(item.imglist, idx, 1)"
              ></van-image>
            </div>
            <div class="flex-grow-0 mgt5" v-if="item.afterimglist">
              <span>处理后</span>
              <van-image
                v-for="(img, idx) in item.afterimglist"
                :key="idx"
                width="1.3333rem"
                height="1.3333rem"
                :src="img.srca"
                @click="showImagePreview(item.afterimglist, idx, 2)"
              ></van-image>
            </div>
            <div class="flex-grow-0 mgt5">
              <!-- {{ parseTime(item.disposeTime) }} -->
            </div>
          </div>
        </van-step>
      </van-steps>
    </van-cell-group>
    <!-- 上报按钮 -->
    <template v-if="detail.status == '1'">
      <div class="flex-row btn-group">
        <!-- <template v-if="recordList.length == 0">
          <van-button
            class="btn"
            block
            :loading="loading"
            type="primary"
            @click="submit('开始处理')"
            :to="{ path: '/gongdan/handle', query: { id: id } }"
            >开始处理</van-button
          >
        </template> -->
        <template>
          <!-- <van-button
            block
            class="btn"
            :loading="loading"
            type="primary"
            @click="submit('确认提交')"
            >确认提交</van-button
          > -->
          <van-button
            block
            :disabled="isclick"
            class="btn"
            type="info"
            @click="chuli"
            >处理</van-button
          >
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import {
  getGongdanDetail,
  getGongdanRecordList,
  saveGongdanRecord,
  siteDetail,
} from "@/api/gongdan";
import { getImage } from "@/utils/image";
import {
  Cell,
  CellGroup,
  Toast,
  Image,
  ImagePreview,
  ActionSheet,
  Tag,
  Step,
  Steps,
  Button,
  NavBar,
  Icon,
} from "vant";
import Vue from "vue";

Vue.use(Toast);
import FieldLabel from "@/components/fieldLabel";
import { getStorage } from "@/utils/auth";
import BMap from "BMap";
import Header from "@/components/header";
import wx from "weixin-js-sdk";
export default {
  name: "GongdanDetail",
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Image.name]: Image,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    [ImagePreview.Component.name]: ImagePreview.Component,
    FieldLabel,
    Header,
  },

  data() {
    return {
      loading: false,
      detail: {},
      id: "147258369",
      title: "我是标题",
      lat: 0,
      statu:0,
      distance:0,
      isclick:true,
      lng: 0,
      recordList: [],
      stationdetail: {},
      actions: [
        { id: "baidu", name: "百度地图" },
        { id: "qq", name: "腾讯地图" },
        { id: "gaode", name: "高德地图" },
      ],
      form: {
        orderId: "", //工单id
        orderName: "", //工单名字
        disposeId: getStorage("uid"), //处理人id 当前登录人
        disposeName: getStorage("name"), //处理人名字 当前登录人
        disposeResult: "", //处理结果
        //disposeTime: "", //处理时间
        address: "", //地址
      },
    };
  },
  computed: {
    mapImg() {
      let map = null;
      if (this.detail.lat) {
        map = `https://api.map.baidu.com/staticimage/v2?ak=${process.env.VUE_APP_MAP_KEY}&mcode=666666&center=${this.detail.lng},${this.detail.lat}&width=375&height=200&zoom=16&markers=${this.detail.lng},${this.detail.lat}`;
      } else {
        map = `https://api.map.baidu.com/staticimage/v2?ak=${process.env.VUE_APP_MAP_KEY}&mcode=666666&center=118.54477,28.673444&width=375&height=200&zoom=16&markers=118.54477,28.673444`;
      }
      return map;
    },
  },
  created() {
    this.parseTime = this.utils.parseTime;
    const id = this.$route.query.id;
    this.statu=this.$route.query.status
    if (isNaN(id)) {
      return Toast.fail("参数错误！");
    }
    this.id = id;
    this.loadData();
    this.initMap();
    //调用获取站点详情的方法
  },
  mounted() {
    const isformh = this.$route.query.isformh;
    if (isformh == "true") {
      this.submit("确认提交");
    }
  },
  updated() {},
  watch: {
       lng: {
	        handler (val, olVal) {
            var that=this
	          var map = new BMap.Map("allmap");
      var point = new BMap.Point(116.404, 39.915);
      var pointAttendance;
      var lngs = that.lng;
      // alert('lngs是'+lngs)
      console.log("lng是", lngs);
      var lats = that.lat;
      
      pointAttendance = new BMap.Point(lngs, lats); //站点位置
      new BMap.Geolocation().getCurrentPosition(
        function (r) {
          if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            //通过Geolocation类的getStatus()可以判断是否成功定位。
            console.log('r是',r);
            point = new BMap.Point(r.point.lng, r.point.lat); // 获取自己当前位置经纬度
            pointAttendance = new BMap.Point(lngs, lats); //站点位置
            // alert('我是'+JSON.stringify(pointAttendance))
            // this.pointAttendance = pointAttendance;
            // console.log(this.pointAttendance);
          }
        },

        { enableHighAccuracy: true }
      );
      console.log("pointAttendance是", pointAttendance);
       const a=new BMap.Point(120,32);
             const b=new BMap.Point(118.6,28.7);
             const c=(map.getDistance(a,b).toFixed(2))
            //  alert('c是'+c)
      //        
      
       setTimeout(function () {
        
        console.log("pointAttendance是", pointAttendance);
            console.log("point是",point);
            // alert("point是"+JSON.stringify(point));
            // alert("pointAttendance是"+JSON.stringify(pointAttendance));
                 let distance = (map.getDistance(pointAttendance,point).toFixed(2)); //获取本人与站点之间的距离
           console.log('distance',distance);
          //  alert('距离是'+distance)
         that.distance=distance
          that.isclick=false
              }, 5000);
             
       
	        },
	        deep: true
	      },
  
  },
  methods: {
    chuli(){
      if (this.distance<=500) {
        this.$router.push({ path: '/gongdan/handle', query: { id: this.id } })
      }else{
         Toast.fail("不在提交范围内");
      }
    },
    back() {
        if (this.statu==1) {
          this.$router.push({ path:"/gongdan/index", query: { status: this.statu } });
        }else{
          this.$router.push("/gongdan/index")
        }
      
    },
    home() {
      this.$router.replace("/");
    },
    //获取站点详情的方法
    async getstation(id) {
      let res = await siteDetail({ id: id });
      this.stationdetail = res.data;
      console.log(this.stationdetail);
    },
    jumpXcx() {
      let _path = window.location.href;
      let _query = this.$route.query.id;
      let detail = this.stationdetail;

      var x_pi = (3.14159265358979324 * 3000.0) / 180.0;
      var x = detail.lng - 0.0065;
      var y = detail.lat - 0.006;
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
      var qqlng = z * Math.cos(theta);
      var qqlat = z * Math.sin(theta);
      this.stationdetail.lng = qqlng
      this.stationdetail.lat = qqlat

      let _url = `/pages/ceshi/ceshi?lat=${this.stationdetail.lat}&lng=${this.stationdetail.lng}&siteAddress=${this.stationdetail.siteAddress}&name=${this.stationdetail.name}&_query=${_query}&_path=${_path}`;
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          wx.miniProgram.navigateTo({
            url: _url,
            // url:`/pages/ceshi/ceshi`
            // url:'/pages/ceshi/ceshi?id='+this.id+'&title='+this.title
          });
          wx.miniProgram.postMessage({
            data: { id: "我是id" },
          });
        }
      });
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      if (item.id == "baidu") {
        this.location(this.baiduMapNav);
      } else if (item.id == "qq") {
        this.location(this.qqMapNav);
      } else if (item.id == "gaode") {
        this.location(this.gaodeMapNav);
      }
      console.log(item);
    },
    //导航
    location(url) {
      location.href = url;
    },
    getImageById(index, idx, id) {
      getImage(id).then((data) => {
        this.recordList[index]["imglist"][idx]["src"] = data;
      });
    },
    getAfterImageById(index, idx, id) {
      getImage(id).then((data) => {
        this.recordList[index]["afterimglist"][idx]["srca"] = data;
      });
    },
    loadData() {
      this.getGongdanDetail();
      this.getGongdanRecordList();
    },
    initMap() {
      //定义获取城市方法
      const geolocation = new BMap.Geolocation();
      var _this = this;
      geolocation.getCurrentPosition(
        function getinfo(position) {
          let address = position.address; //获取城市信息
          // alert('我在'+address.province+address.city)
          console.log("城市信息是", position);
          _this.form.address = `${
            address.province +
            address.city +
            address.district +
            address.street +
            address.street_number
          }`;
        },
        function (e) {
          console.log(e);
          Toast.fail({
            message: "定位失败！",
            forbidClick: true,
          });
        },
        { provider: "baidu" }
      );
    },
    getGongdanDetail() {
      Toast.loading("加载中...");
      const id = this.id;
      getGongdanDetail({ id: id })
        .then((res) => {
          this.detail = res.data;
          console.log(   'res是',res.data);
          this.lat=res.data.lat
          this.lng=res.data.lng
          
          this.getstation(res.data.siteId);
          Toast.clear();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submit(content) {
      const _param = this.form;
      var that = this;
      console.log("submit", _param);
      that.loading = true;
      _param.disposeResult = content;
      // _param.disposeTime = this.parseTime(new Date());
      _param.orderName = that.detail.orderName;
      _param.orderId = that.id;
      saveGongdanRecord(_param)
        .then(() => {
          that.loading = false;
          Toast.success({
            message: "提交成功！",
            forbidClick: true,
          }),
            setTimeout(function () {
              that.loadData();
            }, 1500);
        })
        .catch((res) => {
          this.loading = false;
          console.log(res);
        });
    },
    async getGongdanRecordList() {
      const id = this.id;
      await getGongdanRecordList({ id: id })
        .then((res) => {
          let recordList = res.data;
          console.log(res.data);
          recordList.forEach((item, index) => {
            if (item.files) {
              const fileList = JSON.parse(item.files);
              recordList[index]["imglist"] = fileList.map((img, idx) => {
                item.src = this.getImageById(index, idx, img.id);
                return item;
              });
              console.log("前图片", item);
            }
            if (item.afterFiles) {
              const afterfileList = JSON.parse(item.afterFiles);
              recordList[index]["afterimglist"] = afterfileList.map(
                (img, idx) => {
                  item.srca = this.getAfterImageById(index, idx, img.id);
                  return item;
                }
              );
              console.log("后图片", item);
            }
          });
          console.log(recordList);
          this.recordList = recordList;
          Toast.clear();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //图片预览
    showImagePreview(imglis, index, type) {
      console.log(555);
      console.log(imglis);
      const newImgList = imglis.map((img) => {
        if (type == 1) {
          return img.src;
        } else {
          return img.srca;
        }
      });
      ImagePreview({
        images: newImgList,
        startPosition: index,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form /deep/ {
  padding: 20px 0px;
  .btn {
    width: 175px;
    margin: 20px 100px;
    font-size: 0.42667rem !important;
  }
  .van-field__label {
    flex: none;
    width: 85px;
  }
}
.map {
  width: 345px;
  height: 200px;
}
.van-step--finish {
  color: #999;
}
.btn-group {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .btn {
    border: none;
    border-radius: 0;
    font-size: 0.42667rem !important;
  }
}
</style>
